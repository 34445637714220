import React from 'react';
import { Box, Typography } from '@mui/material';
import HearingIcon from '@mui/icons-material/Hearing'; // Import the ear icon
import { keyframes, styled } from '@mui/system';


const breatheAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

// Styled icon with animation
const AnimatedIcon = styled(HearingIcon)({
  animation: `${breatheAnimation} 2s ease-in-out infinite`,
  fontSize: '6rem',
});

const WaitingToVote = ({ comedianName, countdown }) => {
    return (
        <Box sx={{ textAlign: 'center', p: 2, height: '60vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h4" sx={{ mb: 3 }}>Please give your attention to {comedianName}...</Typography>
            <AnimatedIcon color="secondary"  />
            <Typography variant="h5" sx={{mt: 3}}> This screen will automatically allow voting soon.</Typography>
        </Box>
    );
};

export default WaitingToVote;