import React from 'react';
import { Card, CardContent, Typography, useTheme } from '@mui/material';

const AccessCodeCard = ({ accessCode }) => {
    const theme = useTheme(); // Access the theme

    return (
        <Card 
            className="access-code-card" 
            variant="lifted" 
            sx={{ 
                backgroundColor: `${theme.palette.background.default}A0` // Apply alpha for transparency
            }}
        >
            <CardContent>
                <Typography variant="h3" component="h2" align='center' sx={{ fontFamily: 'monospace', mb:-2 }}>
                    Access Code:
                </Typography>
                <Typography variant="h1" component="h2" align='center' sx={{ fontFamily: 'monospace', color: 'white', fontWeight: 'bold' }}>
                    {accessCode}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default AccessCodeCard;
