import React from 'react';
import { Box, Typography } from '@mui/material';

const WaitingForNextRound = () => {
    return (
        <Box sx={{ textAlign: 'center', p: 2 }}>
            <Typography variant="h4">No active round</Typography>
            <Typography variant="subtitle1">Please wait for the next round to start.</Typography>
        </Box>
    );
};

export default WaitingForNextRound;