import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark', // Enable dark mode
    background: {
      default: '#181a1b' // Set the background color for the theme
    },
  },
  typography: {
    fontFamily: 'Helvetica, sans-serif' // Set the default font family
  },
  // You can also customize other theme aspects like typography here
});

export default theme;