import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, TextField, Button, Modal, List, ListItem, ListItemText, IconButton, Snackbar, Alert } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import SetList from '../comic_comps/Setlist';
import AddComedian from '../comic_comps/AddComedian';

const apiUrl = process.env.REACT_APP_API_URL;

const SetListMaster = () => {
    const [comedians, setComedians] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [showAddModal, setShowAddModal] = useState(false);
    const [sessionId, setSessionId] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('error');

    useEffect(() => {
        fetchActiveSession();
    }, []);

    const fetchActiveSession = async () => {
        try {
            const response = await fetch(`${apiUrl}/GameSession/active`);
            if (!response.ok) throw new Error('Failed to fetch active session');
            const { id } = await response.json();
            setSessionId(id);
            fetchComedians(id);
        } catch (error) {
            console.error('Error fetching active session:', error);
        }
    };

    const fetchComedians = async (sessionId) => {
        try {
            const response = await fetch(`${apiUrl}/gamesessions/${sessionId}/setlist`);
            if (!response.ok) throw new Error('Failed to fetch setlist');
            const data = await response.json();
            setComedians(data.sort((a, b) => a.order - b.order));
        } catch (error) {
            console.error('Error fetching comedians:', error);
        }
    };

    const handleSearchChange = async (e) => {
        setSearchQuery(e.target.value);
        if (e.target.value.length >= 2) {
            try {
                const response = await fetch(`${apiUrl}/comedians?name=${encodeURIComponent(e.target.value)}`);
                if (response.ok) {
                    const data = await response.json();
                    setSearchResults(data);
                } else {
                    setSearchResults([]);
                }
            } catch (error) {
                console.error('Error searching comedians:', error);
                setSearchResults([]);
            }
        } else {
            setSearchResults([]);
        }
    };

    const handleAddComedian = async (comedianId) => {
        try {
            const response = await fetch(`${apiUrl}/gamesessions/${sessionId}/setlist/addComedian`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ComedianId: comedianId }),
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to add comedian');
            }
            fetchComedians(sessionId); // Refresh comedians list
            setSearchQuery(''); // Reset search query
            setSearchResults([]); // Reset search results
            setSnackbarMessage('Comedian added successfully!');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error adding comedian:', error);
            setSnackbarMessage(error.message);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h4" sx={{ mb: 3 }}>Setlist Management</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Search Comedians"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        margin="normal"
                        variant="outlined"
                    />
                    <List>
                        {searchResults.map((comedian) => (
                            <ListItem key={comedian.id}>
                                <ListItemText primary={comedian.name} />
                                <IconButton onClick={() => handleAddComedian(comedian.id)}>
                                    <ArrowForward />
                                </IconButton>
                            </ListItem>
                        ))}
                    </List>
                    <Button variant="contained" color="primary" onClick={() => setShowAddModal(true)}>
                        Add New Comic
                    </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                    <SetList comedians={comedians} fetchComedians={fetchComedians} sessionId={sessionId} />
                </Grid>
            </Grid>
            <Modal
                open={showAddModal}
                onClose={() => setShowAddModal(false)}
                aria-labelledby="add-comedian-modal"
                aria-describedby="add-comedian-form"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Box sx={{
                    width: 400,
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                    boxShadow: 24,
                    p: 2,
                }}>
                    <AddComedian />
                </Box>
            </Modal>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default SetListMaster;
