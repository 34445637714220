import React from 'react';
import { Container, Typography, Card, CardContent, Grid, Button, Grow } from '@mui/material';
import BeerIcon from '@mui/icons-material/LocalBar';
import TacoIcon from '@mui/icons-material/Fastfood';
import EmailOptIn from './EmailOptIn';
import InstagramAd from './InstagramAd';
import { styled } from '@mui/system';

const AnimatedContainer = styled(Container)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(1, 2),
  animation: 'fadeIn 1s ease-in-out',
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 }
  }
}));

const AnimatedCard = styled(Card)(({ theme }) => ({
  transition: 'transform 0.5s',
  '&:hover': {
    transform: 'scale(1.05)'
  }
}));

const WelcomeView = () => {
  return (
    <AnimatedContainer maxWidth="md">
      <Typography variant="h3" gutterBottom>Welcome to The Weakday Show!</Typography>
      <Typography variant="body1" paragraph>Thanks for coming out on a Tuesday.</Typography>
      <Typography variant="body1" paragraph>Hopefully you already have a drink in hand... Let's talk business.</Typography>
      
      <Typography variant="h4" gutterBottom>Game Rules:</Typography>
      <Typography variant="body1" paragraph>Comedians will have 2 minutes to win your heart. You hold the power to decide who stays or goes—simply cast your vote!</Typography>
      <Typography variant="body1" paragraph>Your screen will seamlessly update throughout the different segments of the show. If you think something ain't right, try refreshing your screen.</Typography>
      <Typography variant="body1" paragraph>Thanks again for being part of tonight’s show. Let's get itttt</Typography>

      <Grid container spacing={3}>
        <Grow in timeout={500}>
          <Grid item xs={12} sm={6}>
            <AnimatedCard>
              <CardContent>
                <Typography variant="h4" gutterBottom>Insider Tip...</Typography>
                <Typography variant="body2" paragraph>Exclusive tonight: $5 beers. Make the most of it!</Typography>
                <BeerIcon color="primary" />
              </CardContent>
            </AnimatedCard>
          </Grid>
        </Grow>
        <Grow in timeout={1000}>
          <Grid item xs={12} sm={6}>
            <AnimatedCard>
              <CardContent>
                <Typography variant="h4" gutterBottom>Don't Miss Out!</Typography>
                <Typography variant="body2" paragraph>2 for $10 fish tacos — they're kinda fire ngl</Typography>
                <TacoIcon color="secondary" />
              </CardContent>
            </AnimatedCard>
          </Grid>
        </Grow>
      </Grid>
      <EmailOptIn source="WelcomeView"/>
      <Typography variant="h4" gutterBottom style={{ marginTop: '20px' }}>Wanna be super chill?</Typography>
      <InstagramAd />
    </AnimatedContainer>
  );
};

export default WelcomeView;
