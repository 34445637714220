import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'; // Import the right-arrow icon
import { styled, keyframes } from '@mui/system';

// Create a keyframe animation
const breatheAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.15); }
  100% { transform: scale(1); }
`;

const apiUrl = process.env.REACT_APP_API_URL;
// Style the button with the animation
const AnimatedButton = styled(Button)({
  animation: `${breatheAnimation} 3s ease-in-out infinite`,
});

const OptInView = ({ onConfirm, roundId }) => {
    const handleReady = async () => {
        const deviceId = localStorage.getItem('deviceId');
        const audienceId = localStorage.getItem('audienceId');
        console.log("Opting in...")
        try {
            const response = await fetch(`${apiUrl}/AudienceParticipation/opt-in`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    roundId,
                    audienceId,
                    deviceId,
                    isReady: true,
                    isReadyTime: new Date().toISOString() 
                })
            });
            console.log('Round ID received in OptInView:', roundId);
            if (!response.ok) {
                throw new Error('Failed to opt-in for the round.');
            }

            const result = await response.json();
            onConfirm(); // Notify the parent component that the user is ready
            console.log(result.message); // Optionally handle the response message
        } catch (error) {
            console.error('Opt-in error:', error.message);
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '60vh',  // Full viewport height
                padding: 2
            }}
        >
            <AnimatedButton
                variant="contained"
                color="success"
                size="large"
                onClick={handleReady}
                sx={{ fontSize: '1.5rem', padding: '12px 24px' }}
                endIcon={<ArrowForwardIosIcon />}  // Add the right-pointing arrow icon
            >
                Ready
            </AnimatedButton>
        </Box>
    );
};


export default OptInView;