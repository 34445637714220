import React, { createContext, useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';

export const AuthContext = createContext();
const apiUrl = process.env.REACT_APP_API_URL;
export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                console.log(`decodedToken times 1000 = ${decodedToken.exp * 1000} > ${Date.now()}?`)
                if (decodedToken.exp * 1000 > Date.now()) {
                    console.log(`settingUser with token`)
                    setUser(decodedToken);
                } else {
                    localStorage.removeItem('token');
                }
            } catch (error) {
                console.error("Failed to decode token:", error);
                localStorage.removeItem('token');
            }
        }
    }, []);

    const login = async (username, password) => {
        const response = await fetch(`${apiUrl}/Auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, password })
        });

        if (response.ok) {
            const data = await response.json();
            localStorage.setItem('token', data.token);
            const decodedToken = jwtDecode(data.token);
            setUser(decodedToken);
        } else {
            throw new Error('Login failed');
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
