import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const [lastMessage, setLastMessage] = useState(null);
  const ws = useRef(null);
  const wsUrlg = process.env.REACT_APP_WS_URL_game;
  const pingInterval = useRef(null);

  useEffect(() => {
    const connect = () => {
      ws.current = new WebSocket(wsUrlg);

      ws.current.onopen = () => {
        console.log('WebSocket connection established.');
        pingInterval.current = setInterval(() => {
          if (ws.current && ws.current.readyState === WebSocket.OPEN) {
            ws.current.send(JSON.stringify({ action: 'ping' }));
          }
        }, 5000); // Send a ping every 5 seconds
      };

      ws.current.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.action !== 'ping') {
          setLastMessage(message);
        }
      };

      ws.current.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      ws.current.onclose = (event) => {
        console.log('WebSocket connection closed:', event);
        if (pingInterval.current) {
          clearInterval(pingInterval.current);
        }
        if (!event.wasClean) {
          console.log('WebSocket disconnected unexpectedly, attempting to reconnect...');
          setTimeout(connect, 5000); // Attempt to reconnect after 5 seconds
        }
      };

      return () => {
        if (ws.current) {
          ws.current.close();
        }
        if (pingInterval.current) {
          clearInterval(pingInterval.current);
        }
      };
    };

    connect();
  }, [wsUrlg]);

  return (
    <WebSocketContext.Provider value={lastMessage}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketContext);
