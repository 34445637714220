// StarsAnimation.js

import React, { useEffect, useRef } from 'react';
import './StarsAnimation.css'; 

const StarsAnimation = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;
    const centerX = canvasWidth * 0.5;
    const centerY = canvasHeight * 0.5;
    const numberOfStars = 1500;
    let stars = [];

    function getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function remap(value, from1, to1, from2, to2) {
      return ((value - from1) / (to1 - from1)) * (to2 - from2) + from2;
    }

    class Star {
      constructor() {
        this.x = getRandomInt(-centerX, centerX);
        this.y = getRandomInt(-centerY, centerY);
        this.counter = getRandomInt(1, canvasWidth);
        this.radiusMax = 1 + Math.random() * 4;
        this.speed = getRandomInt(.1, .2);
      }

      drawStar() {
        this.counter -= this.speed;

        if (this.counter < 1) {
          this.counter = canvasWidth;
          this.x = getRandomInt(-centerX, centerX);
          this.y = getRandomInt(-centerY, centerY);
          this.radiusMax = 1 + Math.random() * 4;
          this.speed = getRandomInt(.1, .2);
        }

        let xRatio = this.x / this.counter;
        let yRatio = this.y / this.counter;

        let starX = remap(xRatio, 0, 1, 0, canvasWidth);
        let starY = remap(yRatio, 0, 1, 0, canvasHeight);

        this.radius = remap(this.counter, 0, canvasWidth, this.radiusMax, 0);

        context.beginPath();
        context.arc(starX, starY, this.radius, 0, Math.PI * 2, false);
        context.closePath();

        context.fillStyle = '#FFF';
        context.fill();
      }
    }

    function setup() {
      for (let i = 0; i < numberOfStars; i++) {
        let star = new Star();
        stars.push(star);
      }
    }
    setup();

    function draw(timestamp) {
      context.clearRect(0, 0, canvasWidth, canvasHeight);

      for (let i = 0; i < stars.length; i++) {
        let star = stars[i];
        star.drawStar();
      }

      requestAnimationFrame(draw);
    }
    draw();

    return () => {
      stars = []; // Clean up stars array
    };
  }, []);

  return <canvas ref={canvasRef} width={window.innerWidth} height={window.innerHeight} />;
};

export default StarsAnimation;
