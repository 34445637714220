import React, { useState, useEffect } from 'react';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DeleteIcon from '@mui/icons-material/PersonRemove';
import { Paper, Typography, IconButton, Button } from '@mui/material';

const apiUrl = process.env.REACT_APP_API_URL;

const SortableItem = ({ id, name, onDelete }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const [showConfirmation, setShowConfirmation] = useState(false);

    return (
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
            <div ref={setNodeRef} {...attributes} {...listeners} style={{ ...style, flexGrow: 1, marginRight: 8 }}>
                <Paper elevation={3} style={{ padding: 16, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6">{name}</Typography>
                </Paper>
            </div>
            {!showConfirmation ? (
                <IconButton onClick={() => setShowConfirmation(true)} aria-label="delete">
                    <DeleteIcon />
                </IconButton>
            ) : (
                <div>
                    <Button onClick={() => {
                        onDelete(id);
                        setShowConfirmation(false);
                    }} color="error">Yes</Button>
                    <Button onClick={() => setShowConfirmation(false)}>No</Button>
                </div>
            )}
        </div>
    );
};

const SetList = ({ comedians: initialComedians, fetchComedians, sessionId }) => {
    const [comedians, setComedians] = useState(initialComedians);

    useEffect(() => {
        setComedians(initialComedians);
    }, [initialComedians]);

    const handleDragEnd = async (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const oldIndex = comedians.findIndex(comedian => comedian.id === active.id);
            const newIndex = comedians.findIndex(comedian => comedian.id === over.id);
            const newComedians = [...comedians];
            newComedians.splice(newIndex, 0, newComedians.splice(oldIndex, 1)[0]);
            setComedians(newComedians);

            console.log("Sending reordered SessionComedian IDs:", newComedians.map(item => item.id));

            try {
                const response = await fetch(`${apiUrl}/gamesessions/${sessionId}/setlist/reorderComedians`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(newComedians.map(item => item.id)),
                });
                if (!response.ok) throw new Error('Failed to save new order');
                console.log("Reorder successful");
                fetchComedians(sessionId); // Refresh comedians list after reordering
            } catch (error) {
                console.error('Error reordering comedians:', error);
            }
        }
    };

    const handleDelete = async (comedianId) => {
        try {
            const response = await fetch(`${apiUrl}/gamesessions/${sessionId}/setlist/removeComedian/${comedianId}`, {
                method: 'DELETE',
            });
            if (!response.ok) throw new Error('Failed to delete comedian');
            fetchComedians(sessionId); // Refresh comedians list after deletion
        } catch (error) {
            console.error('Error deleting comedian:', error);
        }
    };

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
    );

    return (
        <div>
            <Typography variant="h6" sx={{ mb: 2 }}>{`Total Spots: ${comedians.length}`}</Typography>
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                <SortableContext items={comedians.map(comedian => comedian.id)} strategy={verticalListSortingStrategy}>
                    {comedians.map((comedian) => (
                        <SortableItem key={comedian.id} id={comedian.id} name={comedian.name} onDelete={handleDelete} />
                    ))}
                </SortableContext>
            </DndContext>
        </div>
    );
};

export default SetList;
