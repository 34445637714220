import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Snackbar, Alert } from '@mui/material';
import { validateSessionCode } from '../../utils/sessionCodeValidator'; 

const SessionCodePrompt = ({ onValidCode }) => {
    const [open, setOpen] = useState(true);
    const [code, setCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isValid, setIsValid] = useState(true); // Track code validity

    useEffect(() => {
        const storedCode = localStorage.getItem('sessionCode');
        if (storedCode) {
            handleValidation(storedCode);
        }
    }, []);

    const handleCodeChange = (event) => {
        const newCode = event.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
        if (newCode.length <= 5) {
            setCode(newCode);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleValidation = (code) => {
        setLoading(true);
        validateSessionCode(code, (isValid, sessionId) => {
            setLoading(false);
            if (isValid) {
                setOpen(false);
                onValidCode(true);
                optInToSession(sessionId); 
            } else {
                setErrorMessage('Invalid session code. Please try again.');
                setSnackbarOpen(true);
                onValidCode(false);
            }
        }, (isValid) => {
            setLoading(false);
            setErrorMessage('Failed to verify session code. Please try again.');
            setSnackbarOpen(true);
            onValidCode(false);
        });
    };
    const apiUrl = process.env.REACT_APP_API_URL;
    const optInToSession = async (sessionId) => {
        const deviceId = localStorage.getItem('deviceId');
        const screenResolution = `${window.screen.width}x${window.screen.height}`;
    
        try {
            const response = await fetch(`${apiUrl}/Audience/create-audience`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'DeviceId': deviceId
                },
                body: JSON.stringify({
                    sessionId,
                    screenResolution
                })
            });
            const data = await response.json();
            console.log("Response data from opt-in:", data);
            if (data.audienceId) {
                console.log("Storing audienceId:", data.audienceId);
                localStorage.setItem('audienceId', data.audienceId.toString());  // Store the Audience ID
            } else {
                console.log("No audienceId found in response:", data);
            }
            onValidCode(true); // Call the callback with true indicating successful opt-in// "You have already joined the session" or "You have successfully joined the session!"
        } catch (error) {
            console.error('Error opting into session:', error);
            onValidCode(false); // Call the callback with false indicating failure
        }
    };

    const handleSubmit = () => {
        if (code.length === 5) {
            handleValidation(code);
        } else {
            setErrorMessage('Session code must be exactly 5 characters.');
            setSnackbarOpen(true);
        }
    };


    return (
        <Dialog open={open} onClose={() => {}} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" sx={{ textAlign: 'center' }}>Enter Session Code</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="sessionCode"
                    label="Session Code"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={code}
                    onChange={handleCodeChange}
                    inputProps={{ maxLength: 5 }}
                    disabled={loading}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit} color="primary" fullWidth disabled={code.length !== 5 || loading}>
                    {loading ? "Processing..." : "Submit"}
                </Button>
            </DialogActions>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert severity="error">{errorMessage}</Alert>
            </Snackbar>
        </Dialog>
    );
};

export default SessionCodePrompt;
