import React from 'react';
import { Container, Typography, Card, CardContent, Grid, Button, Grow } from '@mui/material';
import InstagramAd from './InstagramAd';
import EmailOptIn from './EmailOptIn';
import { styled } from '@mui/system';

const AnimatedContainer = styled(Container)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(1, 2),
  animation: 'fadeIn 1s ease-in-out',
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 }
  }
}));

const AnimatedCard = styled(Card)(({ theme }) => ({
  transition: 'transform 0.5s',
  '&:hover': {
    transform: 'scale(1.05)'
  }
}));

const EndingView = () => {
  return (
    <AnimatedContainer maxWidth="md">
      <Typography variant="h3" gutterBottom>You made it to the end!</Typography>
      <Typography variant="body1" paragraph>Thanks so much for coming out. We hope to see you next Tuesday</Typography>
      <EmailOptIn source="EndingView"/>
      <Typography variant="h4" gutterBottom style={{ marginTop: '20px' }}>Wanna be super chill?</Typography>
      <InstagramAd />
    </AnimatedContainer>
  );
};

export default EndingView;
