import React from 'react';
import { Box, Typography } from '@mui/material';
import SignLanguageIcon from '@mui/icons-material/SignLanguage';
import { keyframes, styled } from '@mui/system';

// Define a keyframe animation for the breathing effect
const breatheAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

// Styled icon with animation
const AnimatedIcon = styled(SignLanguageIcon)({
  animation: `${breatheAnimation} 2s ease-in-out infinite`,
  fontSize: '6rem',
});

const ComingToTheStage = ({ comedianName }) => {
    return (
      <Box sx={{ textAlign: "center", p: 4 }}>
        <Typography variant="h4" sx={{ mt: 2, padding:2}}>
          {comedianName} coming to the stage!
        </Typography>
        <AnimatedIcon color="secondary" />
        <Typography variant="h5" sx={{ mt: 2, padding:1 }}>
          Make some noise!
        </Typography>
      </Box>
    );
};

export default ComingToTheStage;
