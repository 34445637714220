import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';

const EndSessionModal = ({ isOpen, onClose, onEnd }) => {
    const [sales, setSales] = useState('');
    const [postGameNotes, setPostGameNotes] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        const dataToSend = {
            sales: sales ? parseFloat(sales) : null,
            postGameNotes
        };
        console.log("Sending data to server:", dataToSend); // Debugging output
        onEnd(dataToSend);
        onClose(); // Close the modal after submission
    };

    return (
        <Dialog open={isOpen} onClose={onClose} aria-labelledby="end-session-title">
            <DialogTitle id="end-session-title">End Session</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        margin="dense"
                        label="Sales ($)"
                        type="number"
                        value={sales}
                        onChange={e => setSales(e.target.value)}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Post-Game Notes"
                        multiline
                        rows={4}
                        value={postGameNotes}
                        onChange={e => setPostGameNotes(e.target.value)}
                        fullWidth
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit" color="primary">Submit</Button>
                    <Button onClick={onClose} color="secondary">Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default EndSessionModal;
