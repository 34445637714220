import React from 'react';
import { Container, Typography } from '@mui/material';
import { styled } from '@mui/system';
import InstagramAd from '../audiencecomps/nongame_views/InstagramAd';
import EmailOptIn from '../audiencecomps/nongame_views/EmailOptIn';

const AnimatedContainer = styled(Container)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(8, 2),
  animation: 'fadeIn 1s ease-in-out',
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 }
  }
}));

const WeekendView = () => {
  return (
    <AnimatedContainer maxWidth="md">
      <Typography variant="h3" gutterBottom>We'll be back soon.</Typography>
      <Typography variant="body1" paragraph>We have a show every Tuesday. Free of charge. We hope that you can make the next one.</Typography> 
      <Typography variant="body1" paragraph>But hey... in the meantime... Why not drop your email below so we can keep you posted about upcoming events?</Typography> 
      <EmailOptIn source="WeekendView"/>
      <Typography variant="h4" gutterBottom style={{ marginTop: '20px' }}>Wanna be super chill?</Typography>
      <InstagramAd />
    </AnimatedContainer>
  );
};

export default WeekendView;
