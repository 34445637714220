import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import HearingIcon from '@mui/icons-material/Hearing'; // Import the ear icon

const VotingComponent = ({ roundId, comedianName }) => {
    const [hasVoted, setHasVoted] = useState(false);
    const [vote, setVote] = useState(null);
    const [comicStartTime, setComicStartTime] = useState(null);
    const [duration, setDuration] = useState(null);
    const [countdown, setCountdown] = useState(null);
    const deviceId = localStorage.getItem('deviceId');
    const audienceId = parseInt(localStorage.getItem('audienceId'), 10);
    const apiUrl = process.env.REACT_APP_API_URL;
    useEffect(() => {
        const fetchRoundDetails = async () => {
            try {
                const response = await fetch(`${apiUrl}/rounds/${roundId}`);
                const round = await response.json();
                setComicStartTime(new Date(round.comicStartTime));
                setDuration(round.duration);
            } catch (error) {
                console.error("Failed to fetch round details:", error);
            }
        };

        fetchRoundDetails();
        checkVoteStatus();
    }, [roundId, deviceId]);

    useEffect(() => {
        if (comicStartTime && duration) {
            const interval = setInterval(() => {
                const currentTime = new Date();
                const voteStartTime = new Date(comicStartTime.getTime() + duration * 1000);
                const localOptions = { timeZone: 'America/Los_Angeles' };
                const localVoteStartTime = voteStartTime.toLocaleString('en-US', localOptions); // Convert to Los Angeles time
    
                const timeDifference = voteStartTime - currentTime;
                
                console.log("Interval ID:", interval);
                console.log("Current Time:", currentTime);
                console.log("Vote Start Time (UTC):", voteStartTime);
                console.log("Vote Start Time (Los Angeles):", localVoteStartTime);
                console.log("Time Difference:", timeDifference);
    
                if (timeDifference < 0) {
                    clearInterval(interval);
                    setCountdown(null);
                } else if (timeDifference < 10000) { // If there are less than 10 seconds left
                    setCountdown(Math.ceil(timeDifference / 1000));
                }
            }, 1000);
            return () => {
                console.log("Interval Cleared:", interval);
                clearInterval(interval);
            };
        }
    }, [comicStartTime, duration]);

    const checkVoteStatus = async () => {
        try {
            const response = await fetch(`${apiUrl}/AudienceParticipation/check-vote/${roundId}/${deviceId}`);
            const data = await response.json();
            if (data.hasVoted) {
                setHasVoted(true);
                setVote(data.vote);
            }
        } catch (error) {
            console.error("Failed to check voting status:", error);
        }
    };

    const handleVote = (voteType) => {
        if (!roundId || hasVoted || isNaN(audienceId) || !canVote()) {
            console.error("Invalid conditions for voting.");
            return;
        }

        fetch(`${apiUrl}/AudienceParticipation/votes`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                roundId,
                vote: voteType,
                deviceId,
                audienceId
            })
        })
        .then(response => response.json())
        .then(data => {
            if (data.message === "Vote recorded successfully") {
                setHasVoted(true);
                setVote(voteType);
            } else {
                throw new Error(data.message);
            }
        })
        .catch(error => {
            console.error('Error recording vote:', error);
        });
    };

    const canVote = () => {
        if (!comicStartTime || !duration) {
            return false;
        }
        const currentTime = new Date();
        const voteStartTime = new Date(comicStartTime.getTime() + duration * 1000);
        return currentTime >= voteStartTime;
    };

    return (
        <Box sx={{ textAlign: 'center', p: 2, height: '60vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            {hasVoted ? (
                <>
                    <Typography variant="h4" sx={{ mb: 2 }}>Waiting for all votes</Typography>
                    <Button
                        variant="contained"
                        startIcon={vote === 1 ? <ThumbUpIcon /> : <ThumbDownIcon />}
                        sx={{
                            margin: 1,
                            width: '120px',
                            height: '60px',
                            bgcolor: vote === 1 ? 'success.main' : 'error.main',
                            color: 'common.white',
                            '&.Mui-disabled': {
                                color: 'common.white',
                                opacity: 1,
                                bgcolor: vote === 1 ? 'success.main' : 'error.main',
                            }
                        }}
                        disabled
                    >
                        {vote === 1 ? 'Up' : 'Down'}
                    </Button>
                </>
            ) : !canVote() ? (
                <>
                    <HearingIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
                    <Typography variant="h4" sx={{ mb: 1 }}>Please listen to {comedianName}</Typography>
                    {countdown !== null && (
                        <Typography variant="h5">
                            Voting opens in {countdown} seconds
                        </Typography>
                    )}
                </>
            ) : (
                <>
                    <Typography variant="h5" sx={{ mb: 2 }}>Cast a vote for {comedianName}</Typography>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => handleVote(1)}
                        startIcon={<ThumbUpIcon />}
                        sx={{ margin: 1, width: '120px', height: '60px' }}
                    >
                        Up
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleVote(-1)}
                        startIcon={<ThumbDownIcon />}
                        sx={{ margin: 1, width: '120px', height: '60px' }}
                    >
                        Down
                    </Button>
                </>
            )}
        </Box>
    );
};

export default VotingComponent;
