import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

const VotingResults = ({ votesFor, votesAgainst }) => {
    return (
        <Box sx={{ textAlign: 'center', p: 2 }}>
            <Typography variant="h4" gutterBottom>
                Round Ended!
            </Typography>
            <Button variant="contained" color="success" startIcon={<ThumbUpIcon />} sx={{ margin: 1, width: '120px', height: '60px', pointerEvents: 'none' }}>
                {votesFor}
            </Button>
            <Button variant="contained" color="error" startIcon={<ThumbDownIcon />} sx={{ margin: 1, width: '120px', height: '60px', pointerEvents: 'none' }}>
                {votesAgainst}
            </Button>
        </Box>
    );
};

export default VotingResults;
