import React, { useState, useEffect, useContext, useRef } from 'react';
import { Typography, Box, Grid, Card, CardActionArea, CardContent, Button } from '@mui/material';
import StartSessionModal from './session_comps/StartSessionModal';
import EndSessionModal from './session_comps/EndSessionModal';
import RoundsManagement from './round_comps/RoundsManagement';
import ComicSignup from './comic_comps/ComicSignup';
import GameSessions from './session_comps/SessionManagement';
import { AuthContext } from '../admincomps/authcomps/AuthContext';
import VotesCastView from './voting_comps/VotesCastView';
import SetListMaster from '../admincomps/comic_comps/SetlistMaster';
import ResultsComponent from '../admincomps/voting_comps/ResultsComponent'

const AdminScreen = () => {
    const { user, logout } = useContext(AuthContext);
    const [sessionStatus, setSessionStatus] = useState({ message: 'No active session', type: 'normal' });
    const [currentSessionId, setCurrentSessionId] = useState(null);
    const [sessionActive, setSessionActive] = useState(false);
    const [currentComponent, setCurrentComponent] = useState(null);
    const wsUrlw = process.env.REACT_APP_WS_URL_ws;
    const apiUrl = process.env.REACT_APP_API_URL;
    const ws = useRef(null);
    const pingInterval = useRef(null);
    const attemptRef = useRef(0);

    useEffect(() => {
        console.log("sessionActive changed:", sessionActive);
    }, [sessionActive]);

    const connect = () => {
        const socket = new WebSocket(wsUrlw);
        ws.current = socket;

        socket.onopen = () => {
            console.log("WebSocket connection established.");
            setSessionStatus({ message: "WebSocket connected", type: "success" });
            fetchSessionStatus();
            pingInterval.current = setInterval(() => {
                if (socket.readyState === WebSocket.OPEN) {
                    socket.send(JSON.stringify({ action: 'ping' }));
                }
            }, 5000);
            attemptRef.current = 0;
        };

        socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.action !== 'ping') {
                console.log("WebSocket message received:", data);
            }

            if (data.action === 'sessionUpdate') {
                if (data.data.isActive) {
                    setSessionStatus({ message: `Session (${data.data.sessionId}) in session...`, type: 'success' });
                    setSessionActive(true);
                    setCurrentSessionId(data.data.sessionId);
                } else {
                    setSessionStatus({ message: 'No active session', type: 'normal' });
                    setSessionActive(false);
                    setCurrentSessionId(null);
                }
            } else if (data.action !== 'ping') {
                console.log('Unhandled WebSocket message action:', data.action);
            }
        };

        socket.onerror = (error) => {
            console.error("WebSocket error:", error);
            setSessionStatus({ message: "WebSocket error", type: "error" });
        };

        socket.onclose = (event) => {
            console.log("WebSocket connection closed:", event);
            clearInterval(pingInterval.current);
            if (!event.wasClean) {
                setSessionStatus({ message: "WebSocket disconnected unexpectedly", type: "error" });
                setTimeout(connect, Math.min(10000, 500 * Math.pow(2, attemptRef.current++)));
            }
        };

        return socket;
    };

    useEffect(() => {
        const socket = connect();
        return () => {
            if (socket) {
                socket.close(1000, 'Component unmounted');
                console.log('WebSocket connection closed due to component unmounting');
            }
            clearInterval(pingInterval.current);
        };
    }, [wsUrlw]);

    const fetchSessionStatus = async () => {
        try {
            const response = await fetch(`${apiUrl}/GameSession/active`);
            if (response.ok) {
                const { id, isActive } = await response.json();
                console.log("Fetched session status:", { id, isActive });
                setSessionActive(isActive);
                setCurrentSessionId(id);
                setSessionStatus({ message: isActive ? `Session (${id}) in session...` : 'No active session', type: isActive ? 'success' : 'normal' });
            } else {
                throw new Error('Failed to fetch session status');
            }
        } catch (error) {
            console.error('Error fetching session status:', error);
            setSessionStatus({ message: 'Failed to fetch session status', type: 'error' });
        }
    };

    const handleComponentSwitch = (component) => {
        setCurrentComponent(component);
    };

    const componentMap = {
        'game-sessions': <GameSessions 
            sessionActive={sessionActive}
            setSessionActive={setSessionActive}
            currentSessionId={currentSessionId}
            setCurrentSessionId={setCurrentSessionId}
            setSessionStatus={setSessionStatus}
        />,
        'rounds': <RoundsManagement />,
        'comic-signup': <ComicSignup />,
        'results': <ResultsComponent initialSessionId={currentSessionId} />, // Pass currentSessionId or null,
        'setlist': <SetListMaster />
    };

    const cards = [
        { title: 'Manage Game Sessions', componentKey: 'game-sessions', color: 'primary' },
        { title: 'Manage Rounds', componentKey: 'rounds', color: 'primary' },
        { title: 'View Results', componentKey: 'results', color: 'primary' },
        { title: 'Setlist', componentKey: 'setlist', color: 'secondary' }
    ];

    return (
        <Box sx={{ flexGrow: 1, p: 3 }}>
            <Typography variant="h4" gutterBottom>Admin Dashboard</Typography>
            <Typography variant="subtitle1" sx={{ width: '100%', textAlign: 'center', my: 2, bgcolor: sessionStatus.type === 'normal' ? 'grey' : sessionStatus.type === 'success' ? 'green' : 'red', color: 'common.white', p: 1 }}>
                {sessionStatus.message}
            </Typography>
            <Box display="flex" justifyContent="space-between" alignItems="center" my={2}>
                <Typography variant="subtitle1">
                    Welcome, {user ? user.sub : 'Guest'}
                </Typography>
                <Button variant="contained" color="primary" onClick={logout}>
                    Logout
                </Button>
            </Box>
            <Grid container spacing={2}>
                {cards.map((card, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <Card sx={{ height: '100%' }}>
                            <CardActionArea sx={{ height: '100%' }} onClick={() => handleComponentSwitch(card.componentKey)}>
                                <CardContent>
                                    <Typography variant="h6" color={card.color}>
                                        {card.title}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            {currentComponent && componentMap[currentComponent]}
        </Box>
    );
};

export default AdminScreen;
