import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const ComedianSignup = () => {
  const [sessionId, setSessionId] = useState(null);
  const [comedianName, setComedianName] = useState("");
  const [comedianList, setComedianList] = useState([]);
  const [currentSetlist, setCurrentSetlist] = useState([]);
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetch(`${apiUrl}/GameSession/active`)
      .then((response) => response.json())
      .then((data) => {
        if (data.isActive) {
          setSessionId(data.id);
          fetchSetlist(data.id);
        } else {
          setMessage("No active session found.");
          setSnackbarSeverity("error");
          setOpenSnackbar(true);
        }
      })
      .catch((err) => {
        setMessage("Failed to fetch active session: " + err.message);
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      });
  }, []);

  const fetchSetlist = (sessionId) => {
    fetch(`${apiUrl}/gamesessions/${sessionId}/setlist`)
      .then((response) => response.json())
      .then((setlist) => {
        setCurrentSetlist(setlist);
      })
      .catch((err) => {
        console.error("Error fetching setlist:", err);
      });
  };

  const handleComedianNameChange = async (e) => {
    const name = e.target.value;
    setComedianName(name);
    if (name.length >= 2) {
      try {
        const response = await fetch(
          `${apiUrl}/comedians?name=${encodeURIComponent(name)}`
        );
        const data = await response.json();
        console.log(JSON.stringify(data));
        setComedianList(data);
      } catch (err) {
        setMessage("Error fetching comedians: " + err.message);
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      }
    } else {
      setComedianList([]);
    }
  };

  const handleAddComedian = async (comedianId) => {
    if (!sessionId) {
      setMessage("No active session available.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }
    try {
      const response = await fetch(
        `${apiUrl}/gamesessions/${sessionId}/setlist/addComedian`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ ComedianId: comedianId }),
        }
      );
      if (response.ok) {
        setMessage("Comedian successfully added to the setlist.");
        setSnackbarSeverity("success");
        fetchSetlist(sessionId); // Refresh setlist
      } else {
        const errorData = await response.text();
        setMessage(errorData || "Failed to add comedian to setlist.");
        setSnackbarSeverity("error");
      }
      setOpenSnackbar(true);
    } catch (error) {
      setMessage(error.message || "Failed to add comedian to setlist.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box sx={{ maxWidth: 600, margin: "auto", p: 2 }}>
      <Typography variant="h6">Add New Comedian to Session</Typography>
      <TextField
        fullWidth
        label="Enter comedian name"
        value={comedianName}
        onChange={handleComedianNameChange}
        margin="normal"
        variant="outlined"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => comedianList[0] && handleAddComedian(comedianList[0].id)}
        disabled={!comedianList.length}
        sx={{ mb: 2 }}
      >
        Add Comedian
      </Button>
      <List>
        {comedianList.map((comedian) => (
          <ListItem
            button
            key={comedian.id}
            onClick={() => handleAddComedian(comedian.id)}
          >
            <ListItemText primary={comedian.name} />
          </ListItem>
        ))}
      </List>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Current Setlist</TableCell>
            </TableRow>
          </TableHead>
          {currentSetlist.length > 0 ? (
            currentSetlist.map((comedian) => (
              <TableRow key={comedian.id}>
                <TableCell>{comedian.name}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell>No comedians in the setlist</TableCell>
            </TableRow>
          )}
        </Table>
      </TableContainer>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ComedianSignup;
