import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import ComedianList from "./components/admincomps/comic_comps/ComedianList";
import AddComedian from "./components/admincomps/comic_comps/AddComedian";
import AdminScreen from "./components/admincomps/AdminScreen";
import ComicSignup from "./components/admincomps/comic_comps/ComicSignup";
import VotesCastView from "./components/admincomps/voting_comps/VotesCastView";
import Modal from "react-modal";
import RoundsManagement from "./components/admincomps/round_comps/RoundsManagement";
import GameController from "./components/audiencecomps/GameController";
import GameScreen from "./components/gamescreencomps/GameScreen";
import GameScreen_alt from "./components/gamescreencomps/GameScreen_alt";
import EmailConfirmation from "./components/audiencecomps/nongame_views/EmailConfirmation.js";
import SetList from "./components/admincomps/comic_comps/Setlist";
import SetlistMaster from "./components/admincomps/comic_comps/SetlistMaster.js";
import CoHostScreen from "./components/admincomps/CoHostScreen.js";
import LivingSetlist from "./components/comiccomps/LivingSetlist.js";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import theme from "./theme.js";
import { Grid } from "@mui/material";
import Login from "./components/admincomps/authcomps/Login";
import PrivateRoute from "./components/admincomps/authcomps/PrivateRoute";
import { AuthProvider } from "./components/admincomps/authcomps/AuthContext";
import WeekendView from "./components/gamescreencomps/WeekendView.js";

Modal.setAppElement("#root");

function App() {
  const [gameState, setGameState] = useState(null);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchGameState = async () => {
      try {
        const response = await fetch(`${apiUrl}/GameState/state`);
        console.log(`fetching : ${apiUrl}/GameState/state`);
        if (response.ok) {
          const data = await response.json();
          setGameState(data.state);
        } else {
          console.error("Failed to fetch game state");
        }
      } catch (error) {
        console.error("Error fetching game state:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchGameState();
  }, [apiUrl]);

  if (loading) {
    return <div>Loading...</div>; // Show a loading state while fetching game state
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AuthProvider>
          <div className="App">
            <header className="App-header">
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/WDS_Logo_Book.png`}
                    alt="Logo"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      padding: "10px",
                    }}
                  />
                </Grid>
              </Grid>
            </header>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/admin/*"
                element={
                  <PrivateRoute>
                    <AdminScreen />
                  </PrivateRoute>
                }
              />
              {gameState !== "weekend" ? (
                <>
                  <Route path="/play/*" element={<GameController />} />
                  <Route path="/projector" element={<GameScreen_alt />} />
                  <Route path="/setlist" element={<LivingSetlist />} />
                  <Route path="/confirm" element={<ConditionalRoute />} />
                  <Route path="/CoHost" element={<CoHostScreen />} />
                  <Route path="/" element={<Navigate to="/play" />} />
                  <Route path="*" element={<Navigate to="/play" />} />
                </>
              ) : (
                <>
                  <Route path="/" element={<GameController />} />
                  <Route path="*" element={<GameController />} />
                  <Route path="/confirm" element={<ConditionalRoute />} />
                </>
              )}
            </Routes>
          </div>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

const ConditionalRoute = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get('token');
  const email = query.get('email');

  return token && email ? <EmailConfirmation /> : <Navigate to="/" />;
};

export default App;
