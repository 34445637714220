import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Box,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TableSortLabel,
  Button,
} from '@mui/material';
import { styled } from '@mui/system';

// Create styled component for zebra row coloring
const StyledTableRow = styled(TableRow)(({ theme, index }) => ({
  backgroundColor: index % 2 === 0 ? theme.palette.action.hover : 'inherit',
}));

const ResultsComponent = ({ initialSessionId }) => {
  const [data, setData] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('roundNumber');
  const [sessionDetails, setSessionDetails] = useState({});
  const [currentSessionId, setCurrentSessionId] = useState(initialSessionId);
  const [lastSessionId, setLastSessionId] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const initialize = async () => {
      let initialId = initialSessionId;
      if (!initialId) {
        initialId = await fetchLastSession();
      }
      setLastSessionId(await fetchLastSession());
      await fetchSessionDetails(initialId);
      await fetchResults(initialId);
    };

    initialize();
  }, [initialSessionId, apiUrl]);

  const fetchResults = async (id) => {
    try {
      console.log(`Fetching results for session ID: ${id}`);
      const response = await axios.get(`${apiUrl}/Results/Session/${id}`);
      const responseData = response.data.map((item, index) => ({
        ...item,
        roundNumber: index + 1,
      }));
      setData(responseData);
    } catch (error) {
      console.error('Error fetching data:', error);
      setData([]); // Clear data if there's an error
    }
  };

  const fetchSessionDetails = async (id) => {
    try {
      console.log(`Fetching session details for session ID: ${id}`);
      const response = await axios.get(`${apiUrl}/GameSession/${id}`);
      setSessionDetails(response.data);
      setCurrentSessionId(id);
    } catch (error) {
      console.error('Error fetching session details:', error);
      setSessionDetails({});
    }
  };

  const fetchLastSession = async () => {
    try {
      console.log('Fetching last session ID');
      const response = await axios.get(`${apiUrl}/GameSession/Last`);
      return response.data.sessionId;
    } catch (error) {
      console.error('Error fetching last session:', error);
      return null;
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortData = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const sortedData = sortData(data, getComparator(order, orderBy));

  const handlePreviousSession = async () => {
    if (currentSessionId > 1) {
      const previousSessionId = currentSessionId - 1;
      console.log(`Fetching previous session ID: ${previousSessionId}`);
      await fetchSessionDetails(previousSessionId);
      await fetchResults(previousSessionId);
    }
  };

  const handleNextSession = async () => {
    if (currentSessionId < lastSessionId) {
      const nextSessionId = currentSessionId + 1;
      console.log(`Fetching next session ID: ${nextSessionId}`);
      await fetchSessionDetails(nextSessionId);
      await fetchResults(nextSessionId);
    }
  };

  return (
    <TableContainer component={Paper}>
      <Typography variant="h4" gutterBottom>
        Results
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Button onClick={handlePreviousSession}>&larr; Previous Session</Button>
        <Typography variant="h6">
          {sessionDetails.date &&
            new Date(sessionDetails.date).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}{' '}
          - {sessionDetails.sessionCode}
        </Typography>
        <Button onClick={handleNextSession} disabled={currentSessionId >= lastSessionId}>
          Next Session &rarr;
        </Button>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'comedianName'}
                direction={orderBy === 'comedianName' ? order : 'asc'}
                onClick={() => handleRequestSort('comedianName')}
              >
                Comedian Name
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'audienceSize'}
                direction={orderBy === 'audienceSize' ? order : 'asc'}
                onClick={() => handleRequestSort('audienceSize')}
              >
                Audience Size
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'votesUp'}
                direction={orderBy === 'votesUp' ? order : 'asc'}
                onClick={() => handleRequestSort('votesUp')}
              >
                Votes Up
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'votesDown'}
                direction={orderBy === 'votesDown' ? order : 'asc'}
                onClick={() => handleRequestSort('votesDown')}
              >
                Votes Down
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'votesUpPercentage'}
                direction={orderBy === 'votesUpPercentage' ? order : 'asc'}
                onClick={() => handleRequestSort('votesUpPercentage')}
              >
                Votes Up (%)
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'votesDownPercentage'}
                direction={orderBy === 'votesDownPercentage' ? order : 'asc'}
                onClick={() => handleRequestSort('votesDownPercentage')}
              >
                Votes Down (%)
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'roundNumber'}
                direction={orderBy === 'roundNumber' ? order : 'asc'}
                onClick={() => handleRequestSort('roundNumber')}
              >
                Round
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'time'}
                direction={orderBy === 'time' ? order : 'asc'}
                onClick={() => handleRequestSort('time')}
              >
                Time
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.length > 0 ? (
            sortedData.map((row, index) => (
              <StyledTableRow key={row.roundId} index={index}>
                <TableCell sx={{ fontSize: '1.1rem' }}>{row.comedianName}</TableCell>
                <TableCell sx={{ fontSize: '1.1rem' }}>{row.audienceSize}</TableCell>
                <TableCell sx={{ fontSize: '1.1rem' }}>{row.votesUp}</TableCell>
                <TableCell sx={{ fontSize: '1.1rem' }}>{row.votesDown}</TableCell>
                <TableCell sx={{ fontSize: '1.1rem' }}>
                  {row.votesUpPercentage !== undefined
                    ? row.votesUpPercentage.toFixed(2)
                    : 'N/A'}
                  %
                </TableCell>
                <TableCell sx={{ fontSize: '1.1rem' }}>
                  {row.votesDownPercentage !== undefined
                    ? row.votesDownPercentage.toFixed(2)
                    : 'N/A'}
                  %
                </TableCell>
                <TableCell sx={{ fontSize: '1.1rem' }}>{row.roundNumber}</TableCell>
                <TableCell sx={{ fontSize: '1.1rem' }}>
                  {row.time ? new Date(row.time).toLocaleString() : 'N/A'}
                </TableCell>
              </StyledTableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={8} sx={{ textAlign: 'center', fontSize: '1.1rem' }}>
                No data available for this session.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ResultsComponent;
