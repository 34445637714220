import React, { useState, useEffect, useCallback, useRef } from "react";
import { Box, Typography, Snackbar, Alert } from "@mui/material";

import WelcomeView from "./nongame_views/WelcomeView";
import WelcomeView_Ticketed from "./nongame_views/WelcomeView_Ticketed";
import EndingView from "./nongame_views/EndingView";
import VotingComponent from "./VotingComponent";
import WaitingForNextRound from "./WaitingForNextRound";
import SuggestionComponent from "./SuggestionComponent";
import ComingToTheStage from "./ComingToTheStage";
import ResultsComponent from "../admincomps/voting_comps/ResultsComponent";
import OptInView from "./OptInView";
import SessionCodePrompt from "./SessionCodePrompt";
import VotingResults from "./VotingResults";
import WeekendView from "../gamescreencomps/WeekendView"; // Import WeekendView
import { validateSessionCode } from "../../utils/sessionCodeValidator";
import WaitingToVote from "./WaitingToVote";

const GameController = () => {
  const [currentState, setCurrentState] = useState("intro");
  const [message, setMessage] = useState("");
  const [currentRoundId, setCurrentRoundId] = useState(null);
  const [isOptedIn, setIsOptedIn] = useState(true);
  const [deviceId, setDeviceId] = useState(() => localStorage.getItem("deviceId"));
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [sessionCodeValidated, setSessionCodeValidated] = useState(false);
  const [votesFor, setVotesFor] = useState(0);
  const [votesAgainst, setVotesAgainst] = useState(0);
  const wsUrlg = process.env.REACT_APP_WS_URL_game;
  const apiUrl = process.env.REACT_APP_API_URL;
  const ws = useRef(null);
  const pingInterval = useRef(null);

  useEffect(() => {
    // Only generate and set a new deviceId if it doesn't already exist
    if (!localStorage.getItem("deviceId")) {
      const newDeviceId = Math.random().toString(36).substring(2, 15) +
                          Math.random().toString(36).substring(2, 15);
      localStorage.setItem("deviceId", newDeviceId);
      setDeviceId(newDeviceId); // Update state with new deviceId
    } else {
      // Ensure state is updated with the existing deviceId if not already set
      setDeviceId(localStorage.getItem("deviceId"));
    }
  }, []); // No dependencies, this runs only once when the component mounts

  const connect = () => {
    const socket = new WebSocket(wsUrlg);
    ws.current = socket;

    socket.onopen = () => {
      console.log("WebSocket connection established.");
      pingInterval.current = setInterval(() => {
        if (socket.readyState === WebSocket.OPEN) {
          socket.send(JSON.stringify({ action: 'ping' }));
        }
      }, 5000); // Send a ping every 5 seconds
    };

    socket.onmessage = (event) => {
      const { action, data } = JSON.parse(event.data);
      if (action !== 'ping') {
        console.log("Received WebSocket message:", { action, data });
      }

      switch (action) {
        case "START_GAME":
          handleSessionCodeValidation();
          setMessage(data.message);
          setCurrentState(action);
          break;
        case "START_ROUND":
          if (data.roundId !== currentRoundId) {
            console.log(`START_ROUND RECEIVED FOR ROUND ` + data.roundId);
            setCurrentRoundId(data.roundId);
            setIsOptedIn(false);
            setCurrentState(action);
            setMessage(data.comedianName);
          }
          break;
        case "COMIC_START":
          setCurrentState(action);
          setMessage(data.comedianName);
          break;
        case "VOTE_START":
          setCurrentState(action);
          setMessage(data.comedianName + "!");
          break;
        case "CANCEL_ROUND":
          setCurrentState("waiting_for_next_round");
          break;
        case "END_ROUND_VOTING":
          clearOptInStatus(currentRoundId);
          setCurrentState(action);
          setVotesFor(data.votesFor);
          setVotesAgainst(data.votesAgainst);
          break;
        case "GAME_END":
          clearOptInStatus(currentRoundId);
          setCurrentState(action);
          setMessage(data.message);
          break;
        default:
          if (action !== 'ping') {
            console.error("Received unknown action type:", action);
          }
      }
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    socket.onclose = (event) => {
      console.log("WebSocket connection closed:", event);
      clearInterval(pingInterval.current); // Clear the ping interval
      if (!event.wasClean) {
        console.log("WebSocket disconnected unexpectedly, attempting to reconnect...");
        setTimeout(connect, 5000); // Attempt to reconnect after 5 seconds
      }
    };

    return socket;
  };

  useEffect(() => {
    const socket = connect();
    return () => {
      if (socket) {
        socket.close(1000, 'Component unmounted'); // Clean closure with code 1000 and reason
        console.log('WebSocket connection closed due to component unmounting');
      }
      clearInterval(pingInterval.current);
    };
  }, [wsUrlg]);

  useEffect(() => {
    fetchGameState();
  }, []);

  useEffect(() => {
    // Save the current state to localStorage whenever it changes
    localStorage.setItem("currentState", currentState);
  }, [currentState]);

  useEffect(() => {
    const optedIn = localStorage.getItem(`optedIn-${currentRoundId}`) === "true";
    setIsOptedIn(optedIn);
  }, [currentRoundId]);

  const handleSessionCodeValidation = (isValid) => {
    setSessionCodeValidated(isValid);
  };

  const fetchGameState = async () => {
    try {
      const response = await fetch(`${apiUrl}/GameState/state`);
      const gameState = await response.json();
      console.log(`gamestate response: ${JSON.stringify(gameState)}`);
      if (gameState) {
        setCurrentRoundId(gameState.roundId);
        setMessage(gameState.message);
        setCurrentState(gameState.state.toLowerCase()); // Normalize the state to lowercase
        console.log(`Fetched game state, setting message to: ${message}`);
        const optedIn = localStorage.getItem(`optedIn-${gameState.roundId}`) === 'true';
        setIsOptedIn(optedIn);
        // Special handling if the state is 'END_ROUND_VOTING' or 'CANCEL_ROUND'
        if (gameState.state === "END_ROUND_VOTING" || gameState.state === "CANCEL_ROUND") {
          setCurrentState("waiting_for_next_round");
        }
      }
    } catch (error) {
      console.error('Error fetching game state:', error);
      setMessage('Failed to fetch game state.');
      setOpenSnackbar(true);
    }
  };

  const handleOptInConfirmation = useCallback(() => {
    console.log("handleOptInConfirmation has been called -----");
    if (!isOptedIn) {
      setIsOptedIn(true);
      localStorage.setItem(`optedIn-${currentRoundId}`, "true");
      setCurrentState("vote_start");
      console.log(`Opted in for round: ${currentRoundId}, transitioning to voting state.`);
    }
  }, [isOptedIn, currentRoundId]);  // Dependencies required for the useCallback

  const clearOptInStatus = (roundId) => {
    localStorage.removeItem(`optedIn-${roundId}`);
    console.log(`Opt-in status cleared for round: ${roundId}`);
  };
  
  const renderContent = () => {
    console.count("renderContent calls");
    // Check if today is Tuesday
    const today = new Date();
    const isTuesday = today.getDay() === 2;

    if (
      !sessionCodeValidated &&
      currentState !== "game_end" &&
      currentState !== "weekend"
    ) {
      return <SessionCodePrompt onValidCode={handleSessionCodeValidation} />;
    }
    console.log(
      `You're opted in for this round... Let's see what our currentState is ${currentState.toLowerCase()}`
    );
    switch (currentState.toLowerCase()) {
      case "start_game":
        return isTuesday ? <WelcomeView /> : <WelcomeView_Ticketed />;
      case "start_round":
        return <ComingToTheStage comedianName={message} />;
      case "comic_start":
        return <WaitingToVote comedianName={message} />;
      case "vote_start":
        console.log(`vote_start : var message = ${message}`);
        return isOptedIn ? (
          <VotingComponent
            roundId={currentRoundId}
            message={message}
            comedianName={message}
          />
        ) : (
          <OptInView
            onConfirm={handleOptInConfirmation}
            roundId={currentRoundId}
            message={message}
          />
        );
      case "end_round_voting":
        return (
          <VotingResults votesFor={votesFor} votesAgainst={votesAgainst} />
        );
      case "waiting_for_next_round":
        return <WaitingForNextRound />;
      case "game_end":
        return <EndingView message={message} />;
      case "opt_in":
        return (
          <OptInView
            onConfirm={handleOptInConfirmation}
            roundId={currentRoundId}
          />
        );
      case "suggestions":
        return isOptedIn ? (
          <SuggestionComponent />
        ) : (
          <Typography>
            Please opt in to participate in the suggestions.
          </Typography>
        );
      case "results":
        return <ResultsComponent />;
      case "weekend":
        return <WeekendView />;
      default:
        return (
          <Typography variant="body1">
            {message || "Waiting for the next phase..."}
          </Typography>
        );
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      {renderContent()}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
        <Alert severity="error">{message}</Alert>
      </Snackbar>
    </Box>
  );
};

export default GameController;
