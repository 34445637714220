import React from 'react';
import { Container, Typography, Card, Divider, CardContent, Grid, Button, Grow } from '@mui/material';
import BeerIcon from '@mui/icons-material/LocalBar';
import TacoIcon from '@mui/icons-material/Fastfood';
import EmailOptIn from './EmailOptIn';
import InstagramAd from './InstagramAd';
import { styled } from '@mui/system';

const AnimatedContainer = styled(Container)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(1, 2),
  animation: 'fadeIn 1s ease-in-out',
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 }
  }
}));

const AnimatedCard = styled(Card)(({ theme }) => ({
  transition: 'transform 0.5s',
  '&:hover': {
    transform: 'scale(1.05)'
  }
}));

const WelcomeView = () => {
  return (
    <AnimatedContainer maxWidth="md">
      <Typography variant="h3" gutterBottom>Welcome to The Weakday Show!</Typography>
      <Typography variant="body1" paragraph>Thanks for coming out!</Typography>
      <Typography variant="body1" paragraph>Hopefully you already have a drink in hand... Let's talk business.</Typography>

      <Typography variant="h4" gutterBottom>Game Rules:</Typography>
      <Divider sx={{ bgcolor: 'secondary.main', borderBottomWidth: '4px', marginTop: 2, marginBottom: 1 }} />
      <Divider sx={{ bgcolor: 'secondary.main', borderBottomWidth: '4px', marginTop: 1, marginBottom: 2 }} />
      <Typography variant="body1" paragraph><strong>Step 1:</strong> Comedians will have the first few minutes of their set to win you over. Please give them your full attention and keep chatter to a minimum.</Typography>
      <Typography variant="body1" paragraph><strong>Step 2:</strong> When you see the "Vote Now" message on the projector screen, your phone will prompt you to give a thumbs up or down.</Typography>
      <Typography variant="body1" paragraph><strong>Step 3:</strong> If enough votes are negative, we’ll blast an air horn to end their set and send them to the couch.</Typography>
      <Typography variant="body1" paragraph><strong>Note: </strong>Your screen will automatically update during the show. If something looks off, just refresh your screen.</Typography>
      <Typography variant="h6" gutterBottom>Thank you again for coming out tonight. The show will begin soon.</Typography>
      <Divider sx={{ bgcolor: 'secondary.main', borderBottomWidth: '4px', marginTop: 2, marginBottom: 1 }} />
      <Divider sx={{ bgcolor: 'secondary.main', borderBottomWidth: '4px', marginTop: 1, marginBottom: 2 }} />
    <Typography variant="h6" gutterBottom>Psst.... While you wait... want to stay in touch?</Typography>
      <EmailOptIn source="WelcomeView_Ticketed"/>
      <Typography variant="h5" gutterBottom style={{ marginTop: '20px' }}>Follow the creators of The Weakday Show on IG</Typography>
      <InstagramAd />
    </AnimatedContainer>
  );
};

export default WelcomeView;
