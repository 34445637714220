import React from 'react';

const VotingComponent = () => {
    return (
        <div className="voting-component">
            <h2>Suggestions</h2>
            {/* Example Voting Buttons */}
            <button>Send Suggestion</button>
        </div>
    );
};

export default VotingComponent;
