import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Snackbar, Paper } from '@mui/material';

function AddComedian() {
    const [comedian, setComedian] = useState({ name: '', notes: '' });
    const [message, setMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;
    const handleChange = (event) => {
        setComedian({
            ...comedian,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        fetch(`${apiUrl}/comedians`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(comedian)
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                return response.text().then(text => { throw new Error(text || 'Something went wrong') });
            }
        })
        .then(data => {
            console.log('Success:', data);
            setMessage('Comedian added successfully!');
            setComedian({ name: '', notes: '' }); // Clear the form
            setOpenSnackbar(true);
            setTimeout(() => setOpenSnackbar(false), 3000);
        })
        .catch((error) => {
            console.error('Error:', error);
            setMessage(`Failed to add comedian: ${error.message}`);
            setOpenSnackbar(true);
            setTimeout(() => setOpenSnackbar(false), 3000);
        });
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <Paper sx={{ maxWidth: 500, m: 'auto', p: 3 }}>
            <Typography variant="h5" sx={{ mb: 2 }}>Add New Comedian</Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Name"
                    variant="outlined"
                    name="name"
                    value={comedian.name}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Notes"
                    variant="outlined"
                    name="notes"
                    value={comedian.notes}
                    onChange={handleChange}
                    multiline
                    rows={4}
                    fullWidth
                    margin="normal"
                />
                <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                    Add Comedian
                </Button>
            </form>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                message={message}
            />
        </Paper>
    );
}

export default AddComedian;
