export const validateSessionCode = async (code, onValid, onInvalid) => {
    try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/GameSession/verify-code`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ code })
        });
        const data = await response.json();
        if (data.isValid) {  // Use camelCase as per the JSON response
            localStorage.setItem('sessionCode', code);
            localStorage.setItem('sessionId', data.sessionId);
            onValid(true, data.sessionId);
        } else {
            localStorage.removeItem('sessionCode'); // Optionally remove if invalid
            onInvalid(false);
        }
    } catch (error) {
        console.error('Error verifying session code:', error);
        onInvalid(false);
    }
};