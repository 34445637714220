import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Box} from '@mui/material';
import { styled } from '@mui/system';

const apiUrl = process.env.REACT_APP_API_URL;

const AnimatedContainer = styled(Container)(({ theme }) => ({
  textAlign: 'center',
  animation: 'fadeIn 1s ease-in-out',
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 }
  },
  border: `4px dashed ${theme.palette.secondary.main}`, // Adding the outline
  borderRadius: '15%', // Optional: Adds rounded corners 
  margin: theme.spacing(2,0),
  padding: theme.spacing(2,5),
}));

const EmailPrompt = styled('form')(({ theme }) => ({
  margin: theme.spacing(3, 0),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const EmailOptIn = ({ source }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [honeypot, setHoneypot] = useState('');

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleHoneypotChange = (e) => {
    setHoneypot(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }
    if (honeypot) {
      // Discard submission if honeypot is filled
      return;
    }
    setError('');

    try {
      const response = await fetch(`${apiUrl}/Email/subscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, source, honeypot}),
      });
      if (response.ok) {
        setSubmitted(true);
      } else {
        console.error('Failed to send email');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
    <AnimatedContainer maxWidth="md">
      {!submitted ? (
        <EmailPrompt onSubmit={handleSubmit}>
          <Typography variant="h5" gutterBottom>WDS Email Club</Typography>
         <TextField
            variant="outlined"
            label="Enter your name"
            value={name}
            onChange={handleNameChange}
            required
            style={{ marginBottom: '16px', width: '300px' }}
          />
          <TextField
            variant="outlined"
            label="Enter your email"
            value={email}
            onChange={handleEmailChange}
            required
            style={{ marginBottom: '16px', width: '300px' }}
            error={!!error}
            helperText={error}
          />
          <TextField
            variant="outlined"
            label="Leave this field empty"
            value={honeypot}
            onChange={handleHoneypotChange}
            style={{ display: 'none' }}
          />
          <Typography variant="caption" paragraph>
            (You'll get a free gift once you confirm your email*)
          </Typography>
          <Button variant="contained" color="secondary" type="submit" style={{ width: '200px', fontWeight: '600' }}>
            Submit
          </Button>
        </EmailPrompt>
      ) : (
        <Typography variant="h6" color="secondary">Thank you for subscribing!</Typography>
      )}
    </AnimatedContainer>
    </Box>
  );
};

export default EmailOptIn;
