import React, { useState, useEffect } from 'react';
import { Container, Typography, Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';

const apiUrl = process.env.REACT_APP_API_URL;

const AnimatedContainer = styled(Container)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(8, 2),
  animation: 'fadeIn 1s ease-in-out',
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 }
  }
}));

const EmailConfirmation = () => {
  const [message, setMessage] = useState('Confirming your email...');
  const [isConfirmed, setIsConfirmed] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    const email = query.get('email');

    if (!token || !email) {
      setMessage('Invalid confirmation link.');
      return;
    }

    const confirmEmail = async () => {
      try {
        const response = await fetch(`${apiUrl}/Email/confirm?token=${encodeURIComponent(token)}&email=${encodeURIComponent(email)}`, {
          method: 'GET'
        });

        if (response.ok) {
          setMessage('Your email has been confirmed successfully!');
          setIsConfirmed(true);
        } else {
          setMessage('Failed to confirm your email. The link may be invalid or expired.');
        }
      } catch (error) {
        setMessage('An error occurred while confirming your email.');
      }
    };

    confirmEmail();
  }, [location]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    const email = query.get('email');

    if (!token || !email) {
      navigate('/');
    }
  }, [location, navigate]);

  return (
    <AnimatedContainer maxWidth="md">
      <Box textAlign="center">
        <Typography variant="h4" gutterBottom>{message}</Typography>
        {isConfirmed && (
          <Box mt={2}>
            <CardGiftcardIcon style={{ fontSize: 60, color: 'green' }} />
            <Typography variant="body1" display="block">Enjoy this green gift.</Typography>
          </Box>
        )}
      </Box>
    </AnimatedContainer>
  );
};

export default EmailConfirmation;
