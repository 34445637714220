import React, { useState, useEffect, useRef } from 'react';
import StarsAnimation from './StarsAnimation';
import AccessCodeCard from './cards/AccessCodeCard';
import { Box, Typography, Fade } from '@mui/material';
import './GameScreen_alt.css'; // Ensure you have the required CSS file for animations

const GameScreenAlt = () => {
    const [accessCode, setAccessCode] = useState('');
    const [loading, setLoading] = useState(true);
    const [showVoteAnnouncement, setShowVoteAnnouncement] = useState(false);
    const wsUrl = process.env.REACT_APP_WS_URL_game;
    const apiUrl = process.env.REACT_APP_API_URL;
    const ws = useRef(null);
    const pingIntervalRef = useRef(null);

    useEffect(() => {
        const fetchAccessCode = async () => {
            try {
                const response = await fetch(`${apiUrl}/GameSession/active-session-code`);
                if (!response.ok) {
                    throw new Error('Failed to fetch access code');
                }
                const code = await response.text(); // Assuming the response is just plain text
                setAccessCode(code);
            } catch (error) {
                console.error('Error fetching access code:', error);
                setAccessCode(''); // Clear access code on error
            }
            setLoading(false);
        };

        fetchAccessCode();
    }, [apiUrl]);

    useEffect(() => {
        const connect = () => {
            const socket = new WebSocket(wsUrl);
            ws.current = socket;

            socket.onopen = () => {
                console.log("WebSocket connection established.");
                pingIntervalRef.current = setInterval(() => {
                    if (socket.readyState === WebSocket.OPEN) {
                        socket.send(JSON.stringify({ action: 'ping' }));
                    }
                }, 15000); // Send a ping every 15 seconds
            };

            socket.onmessage = (event) => {
                const { action, data } = JSON.parse(event.data);
                if (action === 'VOTE_START') {
                    setShowVoteAnnouncement(true);
                    setTimeout(() => {
                        setShowVoteAnnouncement(false);
                    }, 5000); // Hide the message after 15 seconds
                }
            };

            socket.onerror = (error) => {
                console.error("WebSocket error:", error);
            };

            socket.onclose = (event) => {
                console.log("WebSocket connection closed:", event);
                clearInterval(pingIntervalRef.current);
                if (!event.wasClean) {
                    console.log("WebSocket disconnected unexpectedly, attempting to reconnect...");
                    setTimeout(connect, 5000); // Attempt to reconnect after 5 seconds
                }
            };

            return socket;
        };

        const socket = connect();
        return () => {
            if (socket) {
                socket.close(1000, 'Component unmounted'); // Clean closure with code 1000 and reason
                console.log('WebSocket connection closed due to component unmounting');
            }
            clearInterval(pingIntervalRef.current);
        };
    }, [wsUrl]);

    return (
        <Box sx={{ position: 'relative', height: '100vh', width: '100vw', overflow: 'hidden' }}>
            <StarsAnimation />
            {!loading && accessCode && <AccessCodeCard accessCode={accessCode} />}
            <Fade in={showVoteAnnouncement} timeout={{ enter: 500, exit: 1500 }}>
                <Box
                    sx={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '100%',
                        height: '20%',
                        backgroundColor: 'green',
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        overflow: 'hidden',
                        zIndex: 1000,
                        whiteSpace: 'nowrap',
                        opacity: showVoteAnnouncement ? 1 : 0,
                        transition: 'opacity 1.5s ease-out',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            animation: 'scroll 60s linear infinite',
                            animationTimingFunction: 'linear',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {[...Array(60)].map((_, index) => (
                            <Typography key={index} variant="h2" sx={{ fontWeight: 'bold', mx: 4 }}>
                                Vote Now!
                            </Typography>
                        ))}
                    </Box>
                </Box>
            </Fade>
            <style>
                {`
                @keyframes scroll {
                    0% {
                        transform: translateX(50%);
                    }
                    100% {
                        transform: translateX(-50%);
                    }
                }
                `}
            </style>
        </Box>
    );
};

export default GameScreenAlt;
