import React, { useEffect, useState } from 'react';
import { styled, keyframes } from '@mui/system';
import { Typography, List, ListItem } from '@mui/material';
import { useWebSocket } from '../websocketcomps/WebSocketContext';

const apiUrl = process.env.REACT_APP_API_URL;

// Define the keyframe animation for the breathing effect
const breatheAnimation = keyframes`
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
`;

const AnimatedListItem = styled(ListItem)({
  animation: `${breatheAnimation} 2s ease-in-out infinite`,
  backgroundColor: 'rgba(0, 255, 0, 0.1)',
  textAlign: 'center',
  justifyContent: 'center',
});

const GreyedOutListItem = styled(ListItem)({
  opacity: 0.5,
  textAlign: 'center',
  justifyContent: 'center',
});

const CenteredListItemText = styled(Typography)({
  textAlign: 'center',
  fontSize: '1.5rem',
});

const CenteredTypography = styled(Typography)({
  textAlign: 'center',
  fontSize: '1.5rem',
});

const LivingSetlist = () => {
  const [comedians, setComedians] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sessionId, setSessionId] = useState(null);
  const lastMessage = useWebSocket();

  const fetchActiveSessionId = async () => {
    try {
      const response = await fetch(`${apiUrl}/GameSession/active`);
      const data = await response.json();
      console.log('Active session ID:', data.id);
      setSessionId(data.id);
    } catch (error) {
      console.error('Error fetching active session ID:', error);
      setLoading(false);
    }
  };

  const fetchSetList = async (sessionId) => {
    try {
      const response = await fetch(`${apiUrl}/gamesessions/${sessionId}/setlist`);
      const data = await response.json();
      console.log('Setlist data:', data);
      setComedians(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching setlist:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const initialize = async () => {
      await fetchActiveSessionId();
    };
    initialize();
  }, []);

  useEffect(() => {
    if (sessionId) {
      fetchSetList(sessionId);
    }
  }, [sessionId]);

  useEffect(() => {
    if (lastMessage) {
      const { action } = lastMessage;
      if (action.startsWith('END_ROUND') || action.startsWith('SETLIST_UPDATE') || action.startsWith('CANCEL_ROUND') || action.startsWith('SETLIST_REMOVAL')) {
        fetchSetList(sessionId);
      }
    }
  }, [lastMessage, sessionId]);

  if (loading) {
    return <CenteredTypography>Loading...</CenteredTypography>;
  }

  return (
    <List>
      {comedians.map((comedian, index) => {
        const listItemContent = (
          <CenteredListItemText key={index} variant="h6">
            {`${index + 1}. ${comedian.name}`}
          </CenteredListItemText>
        );

        if (comedian.hasPerformed) {
          return (
            <GreyedOutListItem key={comedian.id}>
              {listItemContent}
            </GreyedOutListItem>
          );
        }

        if (index === 0 || comedians[index - 1].hasPerformed) {
          return (
            <AnimatedListItem key={comedian.id}>
              {listItemContent}
            </AnimatedListItem>
          );
        }

        return (
          <ListItem key={comedian.id} style={{ textAlign: 'center', justifyContent: 'center' }}>
            {listItemContent}
          </ListItem>
        );
      })}
    </List>
  );
};

export default LivingSetlist;
