import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, LinearProgress, Box, Button } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { useWebSocket } from '../../websocketcomps/WebSocketContext';

const VotesCastView = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [audienceSize, setAudienceSize] = useState(0);
  const [votesDown, setVotesDown] = useState(0);
  const [votesUp, setVotesUp] = useState(0);
  const [roundId, setRoundId] = useState(null);
  const lastMessage = useWebSocket();

  useEffect(() => {
    // Fetch initial data from the API when the component mounts
    const fetchCurrentRoundInfo = async () => {
      try {
        const response = await fetch(`${apiUrl}/Rounds/active`);
        if (response.ok) {
          const data = await response.json();
          setVotesUp(data.votesFor || 0);  // Default to 0 if undefined
          setVotesDown(data.votesAgainst || 0);  // Default to 0 if undefined
          setRoundId(data.id);
        } else {
          console.error('Failed to fetch current round info');
        }
      } catch (error) {
        console.error('Error fetching current round info:', error);
      }
    };

    fetchCurrentRoundInfo();
  }, [apiUrl]);

  useEffect(() => {
    // Fetch audience size once roundId is available
    const fetchAudienceSize = async () => {
      if (roundId) {
        try {
          const response = await fetch(`${apiUrl}/AudienceParticipation/check-audience-size/${roundId}`);
          if (response.ok) {
            const data = await response.json();
            setAudienceSize(data.audienceSize || 0);  // Default to 0 if undefined
          } else {
            console.error('Failed to fetch audience size');
          }
        } catch (error) {
          console.error('Error fetching audience size:', error);
        }
      }
    };

    fetchAudienceSize();
  }, [apiUrl, roundId]);

  useEffect(() => {
    if (lastMessage) {
      const { action, data } = lastMessage;
      switch (action) {
        case 'crowdSizeUpdate':
          setAudienceSize(data.audienceSize || 0);  // Default to 0 if undefined
          break;
        case 'voteUpdate':
          setVotesDown(data.votesAgainst || 0);  // Default to 0 if undefined
          setVotesUp(data.votesFor || 0);  // Default to 0 if undefined
          break;
        case 'END_ROUND_VOTING':
          setVotesDown(0);
          setVotesUp(0);
          setAudienceSize(0);
          setRoundId(null);
          break;
        default:
          break;
      }
    }
  }, [lastMessage]);

  const totalVotes = votesDown + votesUp;
  const progressDown = audienceSize > 0 ? (votesDown / audienceSize) * 100 : 0;
  const progressUp = audienceSize > 0 ? (votesUp / audienceSize) * 100 : 0;
  const allVoted = totalVotes === audienceSize && audienceSize > 0;

  return (
    <Card raised sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h5" component="div" sx={{ display: 'flex', justifyContent: 'space-between', mb: 1.5 }}>
          Audience Size: {audienceSize}
          {allVoted ? (
            <Button color="success" variant="contained">All Votes Cast</Button>
          ) : (
            <Typography>Votes Cast: {isNaN(totalVotes) ? 0 : totalVotes}</Typography>
          )}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1.5 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ThumbUpIcon color="success" />
            <Typography sx={{ ml: 1, color: 'primary' }}>
              {isNaN(votesUp) ? 0 : votesUp} - {isNaN(progressUp) ? 0 : Math.round(progressUp)}%
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ThumbDownIcon color="error" />
            <Typography sx={{ ml: 1, color: 'error' }}>
              {isNaN(votesDown) ? 0 : votesDown} - {isNaN(progressDown) ? 0 : Math.round(progressDown)}%
            </Typography>
          </Box>
        </Box>
        <LinearProgress variant="determinate" value={progressDown} color="error" sx={{ mb: 1, height: 10 }} />
        <LinearProgress variant="determinate" value={progressUp} color="success" sx={{ height: 10 }} />
      </CardContent>
    </Card>
  );
};

export default VotesCastView;
