import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, TextField, FormControl, InputLabel, Select, MenuItem, Typography, Grid } from '@mui/material';
import VotesCastView from '../voting_comps/VotesCastView';
import Timer from './ComicTimer'; // Import the updated Timer component
import { WebSocketProvider, useWebSocket } from '../../websocketcomps/WebSocketContext';

const RoundsManagement = () => {
    const [comedians, setComedians] = useState([]);
    const [selectedComedian, setSelectedComedian] = useState("");
    const [sessionId, setSessionId] = useState(null);
    const [roundType, setRoundType] = useState('');
    const [votingDelay, setVotingDelay] = useState(2); // Default delay for voting
    const [error, setError] = useState('');
    const [roundStarted, setRoundStarted] = useState(false);
    const [roundId, setRoundId] = useState(null);
    const [showVotesCastView, setShowVotesCastView] = useState(false);
    const [roundElapsedTime, setRoundElapsedTime] = useState(0); // Track round duration time
    const roundTimerRef = useRef(null);
    const apiUrl = process.env.REACT_APP_API_URL;
    const lastMessage = useWebSocket(); // Use the WebSocket context

    // Fetch active session details when the component mounts
    useEffect(() => {
        fetchActiveSessionDetails();
    }, [apiUrl]);

    useEffect(() => {
        if (lastMessage) {
            const { action } = lastMessage;
            if (action !== 'ping') {
                console.log('WebSocket message received:', lastMessage);
            }
            if (action.startsWith('END_ROUND') || action.startsWith('SETLIST_UPDATE')) {
                fetchActiveSessionDetails();
                clearRoundTimer();
            }
        }
    }, [lastMessage]);

    const fetchActiveSessionDetails = async () => {
        try {
            const response = await fetch(`${apiUrl}/gamesession/active-details`);
            const data = await response.json();

            if (data.session.isActive) {
                setSessionId(data.session.id);
                await fetchComedians(data.session.id);
                if (data.round && data.round.id) {
                    setRoundId(data.round.id);
                    setRoundType(data.round.type);
                    const selectedComedian = comedians.find(comedian => comedian.name === data.round.comedianName);
                    if (selectedComedian) {
                        setSelectedComedian(selectedComedian.id.toString());
                    }
                    setRoundStarted(true);
                    
                    // Initialize round timer using startTime
                    const roundStart = new Date(data.round.startTime);
                    const now = new Date();
                    const elapsedSeconds = Math.floor((now - roundStart) / 1000);
                    setRoundElapsedTime(elapsedSeconds);
                    startRoundTimer(elapsedSeconds);

                    if (data.round.comicStartTime) {
                        setShowVotesCastView(true);
                    }
                }
            } else {
                setError('No active session.');
            }
        } catch (err) {
            setError('Failed to fetch active session details: ' + err.message);
        }
    };

    // Function to start the round timer
    const startRoundTimer = (initialTime = 0) => {
        clearInterval(roundTimerRef.current); 
        setRoundElapsedTime(initialTime);
        roundTimerRef.current = setInterval(() => {
            setRoundElapsedTime(prevTime => prevTime + 1);
        }, 1000);
    };

    // Function to clear the round timer
    const clearRoundTimer = () => {
        if (roundTimerRef.current) {
            clearInterval(roundTimerRef.current);
            roundTimerRef.current = null;
        }
        setRoundElapsedTime(0);
    };

    // Cleanup timer on component unmount
    useEffect(() => {
        return () => clearRoundTimer();
    }, []);

    // Function to format time
    const formatTime = (totalSeconds) => {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };


    // Function to fetch comedians for a given session
    const fetchComedians = async (sessionId) => {
        try {
            const response = await fetch(`${apiUrl}/gamesessions/${sessionId}/setlist`);
            const data = await response.json();
            if (Array.isArray(data)) {
                setComedians(data);
                console.log(data);
                const { round } = await (await fetch(`${apiUrl}/gamesession/active-details`)).json();
                if (round && round.comedianName) {
                    const selectedComedian = data.find(comedian => comedian.name === round.comedianName);
                    if (selectedComedian) {
                        setSelectedComedian(selectedComedian.id.toString());
                    }
                }
            } else {
                throw new Error('Expected an array of comedians');
            }
        } catch (err) {
            setError('Failed to fetch comedians: ' + err.message);
        }
    };

    const handleStartRound = async () => {
        if (!selectedComedian || !roundType) {
            setError("Please select a comedian and a round type.");
            return;
        }

        if (roundStarted) {
            setError("Round is already in progress.");
            return;
        }

        const roundData = {
            GameSessionId: sessionId,
            ComedianId: parseInt(selectedComedian),
            Type: roundType,
            Status: 'Active',
            Duration: votingDelay
        };

        try {
            const response = await fetch(`${apiUrl}/Rounds/start`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(roundData)
            });

            const data = await response.json();
            if (response.ok) {
                setRoundId(data.id);
                setRoundStarted(true);
                setError(''); // Clear any previous errors
                startRoundTimer(); // Start the round timer on start round
            } else {
                throw new Error(data.message || 'Failed to start round.');
            }
        } catch (err) {
            setError(err.message || 'Error starting round.');
        }
    };

    const handleEndRound = async () => {
        if (!roundId) {
            setError("No round started or round ID missing.");
            return;
        }

        try {
            const response = await fetch(`${apiUrl}/rounds/end/${roundId}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' }
            });
            if (response.ok) {
                setRoundStarted(false);
                clearRoundTimer(); // Stop the round timer
                setError('');
                setShowVotesCastView(false); // Hide VotesCastView when the round ends
                updateComedianPerformed(selectedComedian);
            } else {
                throw new Error('Failed to end round.');
            }
        } catch (err) {
            setError(err.message || 'Error ending round.');
        }
    };

    const updateComedianPerformed = (comedianId) => {
        setComedians(prevComedians => {
            const updatedComedians = prevComedians.map(comedian => {
                if (comedian.id === parseInt(comedianId)) {
                    return { ...comedian, hasPerformed: true };
                }
                return comedian;
            });

            // Automatically select the next comedian who hasn't performed yet
            const nextComedian = updatedComedians.find(comedian => !comedian.hasPerformed);
            if (nextComedian) {
                setSelectedComedian(nextComedian.id.toString()); // Make sure to convert to string if necessary
            } else {
                setSelectedComedian(""); // Reset if no available comedians
            }

            return updatedComedians;
        });
    };

    const handleCancelRound = async () => {
        if (!roundId) {
            setError("No round started or round ID missing.");
            return;
        }
        // Add confirmation dialog
        if (window.confirm("Are you sure you want to cancel this round?")) {
            try {
                const response = await fetch(`${apiUrl}/rounds/cancel/${roundId}`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' }
                });
                if (response.ok) {
                    setRoundStarted(false);
                    clearRoundTimer(); // Stop the round timer
                    setError('');
                    setShowVotesCastView(false); // Hide VotesCastView when the round is canceled
                } else {
                    throw new Error('Failed to cancel round.');
                }
            } catch (err) {
                setError(err.message || 'Error cancelling round.');
            }
        }
    };

    const handleRoundTypeChange = (event) => {
        setRoundType(event.target.value);
    };

    const handleComedianSelection = (event) => {
        setSelectedComedian(event.target.value);
    };

    const handleStartComic = async () => {
        if (!roundId) {
            setError("No round started or round ID missing.");
            return;
        }

        const payload = {
            delayInMinutes: votingDelay  // Ensure this is an integer
        };

        try {
            const response = await fetch(`${apiUrl}/rounds/start-comic/${roundId}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload)
            });
            if (response.ok) {
                console.log("Comic start time set with delay.");
                setShowVotesCastView(true); // Show VotesCastView when the comic timer starts
            } else {
                const errorResponse = await response.json();
                throw new Error(errorResponse.message || 'Failed to set comic start time.');
            }
        } catch (err) {
            setError(err.message || 'Error setting comic start time.');
        }
    };

    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h5" sx={{ mb: 3 }}>Round Management</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <InputLabel id="comedian-label">Select a Comedian</InputLabel>
                        <Select
                            labelId="comedian-label"
                            value={selectedComedian}
                            onChange={handleComedianSelection}
                            label="Select a Comedian"
                        >
                            {comedians.map(comedian => (
                                <MenuItem
                                    key={comedian.id}
                                    value={comedian.id}
                                    style={{ color: comedian.hasPerformed ? 'black' : 'white' }}
                                >
                                    {comedian.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <InputLabel id="round-type-label">Select Round Type</InputLabel>
                        <Select
                            labelId="round-type-label"
                            value={roundType}
                            onChange={handleRoundTypeChange}
                            label="Select Round Type"
                        >
                            <MenuItem value="voting">Voting</MenuItem>
                            <MenuItem value="suggestions">Suggestions</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {roundType === 'voting' && (
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Voting Delay (minutes)"
                            type="number"
                            value={votingDelay}
                            onChange={e => setVotingDelay(Number(e.target.value))}
                            helperText="Delay before voting starts after the comic begins"
                        />
                    </Grid>
                )}
            </Grid>
            <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Button variant="contained" onClick={handleStartRound} disabled={!sessionId || !selectedComedian || roundStarted || !roundType}>
                    Start Round
                </Button>
                {roundStarted && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                        {/* Display round timer */}
                        <Typography variant="h6">
                            Round in Progress... Time: {formatTime(roundElapsedTime)}
                        </Typography>
                        {/* Display comic-specific timer using Timer component */}
                        <Typography variant="h6">
                            Comic Time: <Timer />
                        </Typography>
                        <Button variant="contained" color="secondary" onClick={handleStartComic}>
                            Start Comic Time
                        </Button>
                        <Box sx={{ display: 'flex', gap: 2 }}> {/* Button group */}
                            <Button variant="contained" color="error" onClick={handleEndRound}>
                                End Round
                            </Button>
                            <Button variant="contained" color="warning" onClick={handleCancelRound}>
                                Cancel Round
                            </Button>
                        </Box>
                    </Box>
                )}
                {error && <Typography color="error">{error}</Typography>}
            </Box>
            {showVotesCastView && <VotesCastView />} {/* Conditionally render VotesCastView */}
        </Box>
    );
};

const RoundsManagementWithProvider = () => (
    <WebSocketProvider>
        <RoundsManagement />
    </WebSocketProvider>
);

export default RoundsManagementWithProvider;
