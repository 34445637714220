import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, InputAdornment } from '@mui/material';

const StartSessionModal = ({ isOpen, onClose, onStart }) => {
    const [notes, setNotes] = useState('');
    const [startDate, setStartDate] = useState(moment().tz("America/Los_Angeles").format("YYYY-MM-DD"));
    const [sessionCode, setSessionCode] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;
    
    useEffect(() => {
        if (isOpen) {
            fetchSessionCode();
        }
    }, [isOpen]);

    const fetchSessionCode = async () => {
        try {
            const response = await fetch(`${apiUrl}/GameSession/generate-code`);
            const data = await response.json();
            if (data && data.code) {
                setSessionCode(data.code);
            } else {
                console.error('No code returned from the server', data);
                setSessionCode('ErrorFetchingCode');
            }
        } catch (error) {
            console.error("Failed to fetch session code:", error);
            setSessionCode('Error');
        }
    };

    const handleSessionCodeChange = (event) => {
        let value = event.target.value.toUpperCase();
        value = value.replace(/[^A-Z0-9]/g, '');
        if (value.length <= 5) {
            setSessionCode(value);
        }
    };

    const handleRefreshCode = () => {
        fetchSessionCode();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (sessionCode.length !== 5) {
            console.error('Session code must be exactly 5 characters long');
            return;
        }
        const result = await onStart({ startDate, notes, sessionCode });
        if (result && result.success) {
            onClose();
        }
    };

    return (
        <Dialog open={isOpen} onClose={onClose} aria-labelledby="start-session-title">
            <DialogTitle id="start-session-title">Start New Session</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        margin="dense"
                        label="Start Date"
                        type="date"
                        value={startDate}
                        onChange={e => setStartDate(e.target.value)}
                        fullWidth
                        InputProps={{ notched: true }} // Add notched prop here
                    />
                    <TextField
                        margin="dense"
                        label="Notes"
                        multiline
                        rows={4}
                        value={notes}
                        onChange={e => setNotes(e.target.value)}
                        fullWidth
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                        <TextField
                            label="Session Code"
                            type="text"
                            value={sessionCode || ''}
                            onChange={handleSessionCodeChange}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleRefreshCode}>
                                            <RefreshIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" color="primary">Start Session</Button>
                    <Button onClick={onClose} color="secondary">Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};


export default StartSessionModal;
