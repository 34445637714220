import React from 'react';
import { Box, Grid } from '@mui/material';
import VotesCastView from './voting_comps/VotesCastView';
import LivingSetlist from '../comiccomps/LivingSetlist';
import Timer from './round_comps/ComicTimer';
import { WebSocketProvider } from '../websocketcomps/WebSocketContext';

const CoHostScreen = () => {
  return (
    <WebSocketProvider>
      <Box sx={{ flexGrow: 1, padding: 2 }}>
        <Grid container spacing={2}>
        <Grid item xs={12}>
            <Timer />
          </Grid>
          <Grid item xs={12}>
            <VotesCastView />
          </Grid>
          <Grid item xs={12}>
            <LivingSetlist />
          </Grid>
        </Grid>
      </Box>
    </WebSocketProvider>
  );
};

export default CoHostScreen;
