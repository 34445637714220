import React from 'react';
import { Card, CardContent, Typography, Button, Box, Grid } from '@mui/material';
import { styled } from '@mui/system';
import InstagramIcon from '@mui/icons-material/Instagram';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0px 10px 20px rgba(0,0,0,0.2)',
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  height: '100%', // Ensures the cards are the same size
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row', // Ensures consistent layout on mobile
    height: 'auto', // Allow auto height for stacking on mobile
  },
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  width: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    width: '50%', // Keep image at 50% width on mobile
    height: 'auto', // Allow auto height for images on mobile
  },
}));

const ContentContainer = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),
  width: '50%',
  [theme.breakpoints.down('sm')]: {
    width: '50%', // Keep content at 50% width on mobile
    padding: theme.spacing(2),
  },
}));

const FollowButton = styled(Button)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
  backgroundColor: '#ce93d8',
  padding: theme.spacing(1),
  color: 'black',
  '&:hover': {
    backgroundColor: '#BD0F56',
  },
  fontWeight: "600"
}));

const InstagramAd = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <StyledCard>
          <ImageContainer>
            <img src={`${process.env.PUBLIC_URL}/assets/DavidMay_ripped.png`} alt="David May" style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
          </ImageContainer>
          <ContentContainer>
            <Typography variant="h5" gutterBottom align="center">
              Follow David
            </Typography>
            <FollowButton
              startIcon={<InstagramIcon />}
              href="https://www.instagram.com/gods_father"
              target="_blank"
              rel="noopener noreferrer"
            >
              Follow
            </FollowButton>
          </ContentContainer>
        </StyledCard>
      </Grid>
      <Grid item xs={12} sm={6}>
        <StyledCard>
          <ContentContainer>
            <Typography variant="h5" gutterBottom align="center">
              Follow Nath
            </Typography>
            <FollowButton
              startIcon={<InstagramIcon />}
              href="https://www.instagram.com/nathaniel.c.webb/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Follow
            </FollowButton>
          </ContentContainer>
          <ImageContainer>
            <img src={`${process.env.PUBLIC_URL}/assets/Nathaniel_Ripped.png`} alt="Nathaniel Webb" style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
          </ImageContainer>
        </StyledCard>
      </Grid>
    </Grid>
  );
};

export default InstagramAd;
