import React, { useState, useEffect, useRef } from 'react';
import { useWebSocket } from '../../websocketcomps/WebSocketContext';

const Timer = () => {
  const [time, setTime] = useState(0);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const lastMessage = useWebSocket();
  const timerRef = useRef(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  // Fetch the current round info to get start time when component mounts
  useEffect(() => {
    const fetchCurrentRoundInfo = async () => {
      try {
        const response = await fetch(`${apiUrl}/Rounds/active`);
        if (response.ok) {
          const data = await response.json();
          if (data.comicStartTime) {
            const comicStart = new Date(data.comicStartTime);
            const now = new Date();
            const elapsedSeconds = Math.floor((now - comicStart) / 1000);
            setTime(elapsedSeconds);
            startTimer(elapsedSeconds);
            setIsTimerActive(true);
          }
        } else {
          console.error('Failed to fetch current round info');
        }
      } catch (error) {
        console.error('Error fetching current round info:', error);
      }
    };

    fetchCurrentRoundInfo();
  }, [apiUrl]);

  // Handle WebSocket messages to update the timer
  useEffect(() => {
    if (lastMessage) {
      const { action } = lastMessage;

      if (action === 'COMIC_START') {
        setTime(0);
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }
        timerRef.current = setInterval(() => {
          setTime((prevTime) => prevTime + 1);
        }, 1000);
        setIsTimerActive(true);
      }

      if (action.startsWith('END_ROUND') || action.startsWith('CANCEL_ROUND')) {
        setTime(0);
        if (timerRef.current) {
          clearInterval(timerRef.current);
          timerRef.current = null;
        }
        setIsTimerActive(false);
      }
    }
  }, [lastMessage]);

  // Start the timer from a given initial time
  const startTimer = (initialTime) => {
    clearInterval(timerRef.current);
    setTime(initialTime);
    timerRef.current = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);
  };

  // Cleanup timer on component unmount
  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <>
      {formatTime(time)}
    </>
  );
};

export default Timer;
